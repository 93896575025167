// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.watch-providers-logo-and-name-container{
    display: flex;
    flex-flow: row;
    align-items: center;
    margin-left: -25px;
    margin-top: 5px;
}

.watch-providers-logo{
    width: 40px ;
    height: 40px;
    border-radius: 15px;
}
.popover-body {
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    margin: -5px;
}
.watch-providers-name{
    flex-wrap: wrap;
    align-self: center;
    margin-left: 5px;
}

.popover-header {
    background-color: #ab0c0c !important;
    font-weight: 600;
    color: black !important;
    font-size: 20px !important;
    text-align: center;
}
.movie-overview {
    font-weight: 500;
    /* color: white; */
    color: black;
    margin-bottom: -20px;
}
.release-date-and-vote-average{
    text-align: center;
}

.providers-container {
    display: flex;
    justify-content: space-evenly;
}
`, "",{"version":3,"sources":["webpack://./src/styles/watchProviders.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,cAAc;IACd,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,mBAAmB;AACvB;AACA;IACI,yCAAyC;IACzC,kBAAkB;IAClB,YAAY;AAChB;AACA;IACI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,oCAAoC;IACpC,gBAAgB;IAChB,uBAAuB;IACvB,0BAA0B;IAC1B,kBAAkB;AACtB;AACA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;IACZ,oBAAoB;AACxB;AACA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,6BAA6B;AACjC","sourcesContent":["\n.watch-providers-logo-and-name-container{\n    display: flex;\n    flex-flow: row;\n    align-items: center;\n    margin-left: -25px;\n    margin-top: 5px;\n}\n\n.watch-providers-logo{\n    width: 40px ;\n    height: 40px;\n    border-radius: 15px;\n}\n.popover-body {\n    font-family: Arial, Helvetica, sans-serif;\n    text-align: center;\n    margin: -5px;\n}\n.watch-providers-name{\n    flex-wrap: wrap;\n    align-self: center;\n    margin-left: 5px;\n}\n\n.popover-header {\n    background-color: #ab0c0c !important;\n    font-weight: 600;\n    color: black !important;\n    font-size: 20px !important;\n    text-align: center;\n}\n.movie-overview {\n    font-weight: 500;\n    /* color: white; */\n    color: black;\n    margin-bottom: -20px;\n}\n.release-date-and-vote-average{\n    text-align: center;\n}\n\n.providers-container {\n    display: flex;\n    justify-content: space-evenly;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
